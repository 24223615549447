import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs/internal/Observable';
import { LocalStorageService } from '../local-storage.service';
import { SnackBarService } from '../snackbarService';
import { PreviousRouteService } from '../previous-route.service';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {

  auth: any
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService,
    private localStorageService: LocalStorageService,
    private snackBar: SnackBarService,
    private previousRouteService: PreviousRouteService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {

  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (isPlatformBrowser(this.platformId)) {
      this.auth = JSON.parse(localStorage.getItem('auth') + '');
      
      if (!this.auth) {
        this.router.navigate(['signin'], { queryParams: { backUrl: state.url } });
        return false;
      } else if (!this.auth.PaidMember && this.auth.SuitableForAdvantageMembership) {
        this.snackBar.error(this.translate.instant('YOU_DONT_HAVE_PERMISSION_TO_DO_THIS'))
        this.router.navigate([this.translate.currentLang]);
      } else if (!this.auth.PaidMember && !this.auth.SuitableForAdvantageMembership) {
        this.snackBar.error(this.translate.instant('PASIF_MEMBER_MESSAGE'))
        this.router.navigate([this.translate.currentLang + '/signup']);
        return false;
      }
      return true;
    }
    return false;
  }
}
